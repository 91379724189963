import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { H5, TextSm, Text, MarkdownHandler } from '@system'
import classNames from 'classnames'
import { monochrome } from '@helpers/palette'
import Icon from '@system/icon'
import Link from '@system/link'

const useStyles = makeStyles((theme) => ({
  noTextDecoration: {
    textDecoration: 'none',
  },
  outer: {
    height: 'auto',
    margin: '1rem',
    maxWidth: ({ cardModuleMaxWidth }) => cardModuleMaxWidth || '20rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  icon: {
    color: theme.palette.background.slate,
    alignSelf: 'center',
    margin: '0px 4px',
    '&.material-icons': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  grid: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& .gatsby-image-wrapper': {
      borderRadius: ({ variant }) => variant === 'dark' && '4px',
    },
  },
  eyebrow: {
    color: theme.palette.quaternary.main,
    fontWeight: theme.typography.fontWeightExtraBold,
    paddingTop: '24px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '1.5rem',
    },
    display: 'block',
    textAlign: 'center',
    fontSize: '.75rem',
  },
  title: {
    color: ({ titleColor }) =>
      (titleColor?.color && monochrome(titleColor.color)) ||
      theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    paddingTop: '1.5rem',
    display: 'block',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '.75rem',
    },
  },
  extraBoldTitle: {
    fontWeight: theme.typography.fontWeightExtraBold,
  },
  description: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    padding: '16px 0px 24px',
    [theme.breakpoints.up('sm')]: {
      padding: '24px 0px',
    },
    display: 'block',
    textAlign: 'center',
  },
  darkDesc: {
    paddingTop: '0.5rem !important',
  },
  noPaddingDescription: {
    padding: '0px',
  },
  moreLinkSpace: {
    paddingBottom: '24px',
  },
  leftAlign: {
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  rightAlign: {
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  centerAlign: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  link: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.tertiary,
    paddingBottom: '5px',
    textTransform: 'uppercase',
    marginTop: 'auto',
  },
  descriptionInfo: {
    '& p a': {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.text.tertiary,
    },
    '& a': {
      letterSpacing: 0,
      width: 'fit-content',
      margin: '2px auto',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '0.821rem',
      display: 'inline',
      color: theme.palette.text.font,
      borderBottom: '1px solid  #444',
      textDecoration: 'none',
      textTransform: 'uppercase',
    },
    '& a:hover': {
      borderBottom: '1px solid #444',
    },
    '& a[href^="mailto:"]': {
      color: theme.palette.primary.main,
      borderBottom: '0px',

      textTransform: 'lowercase',
    },
  },
  descriptionInfoDark: {
    '& p': {
      color: theme.palette.text.primary,
      fontWeight: `${theme.typography.fontWeightBold} !important`,
      fontSize: '0.821rem !important',
    },
  },
  bottomLogo: {
    alignSelf: 'center',
    maxHeight: '120px',
    padding: '2px 0px',
  },
}))

const ImageTitleDescriptionCard = (props) => {
  const {
    largerDescriptionText,
    alignment,
    image1,
    image2,
    imageLink,
    title,
    titleColor,
    noDescMargin,
    moreLinkSpace,
    description,
    descriptionInfo,
    cta,
    bottomLogo,
    alt,
    extraBoldTitle,
    eyebrow,
    variant,
  } = props.blok
  const { thumbnailHeight, cardWidth, cardModuleMaxWidth } = props

  const classes = useStyles({ titleColor, variant, cardModuleMaxWidth })

  const Description = ({ children, ...otherProps }) => {
    return largerDescriptionText ? (
      <Text {...otherProps}>{children}</Text>
    ) : (
      <TextSm {...otherProps}>{children}</TextSm>
    )
  }
  const ImageLinkWrapper = ({ children }) =>
    imageLink?.cached_url ? (
      <Link link={imageLink} linkStyle={classes.noTextDecoration}>
        {children}
      </Link>
    ) : (
      children
    )

  const isLeftAligned = alignment === 'left'
  const isRightAligned = alignment === 'right'
  const isCenterAligned = alignment === 'center'
  return (
    <SbEditable content={props.blok}>
      <ImageLinkWrapper>
        <Box className={classes.outer}>
          <Box
            className={classNames(classes.grid, {
              [classes.leftAlign]: isLeftAligned,
              [classes.rightAlign]: isRightAligned,
              [classes.centerAlign]: isCenterAligned,
            })}
          >
            {!!image1.length &&
              renderBloks(image1, {
                thumbnailHeight,
                cardWidth: (image1 && image2 && cardWidth * 0.3) || cardWidth,
              })}

            {!!image2 && image2.length !== 0 && (
              <Icon styles={classes.icon}>add</Icon>
            )}
            {!!image2.length &&
              renderBloks(image2, {
                thumbnailHeight,
                cardWidth: (image1 && image2 && cardWidth * 0.3) || cardWidth,
              })}
          </Box>
          {!!eyebrow && (
            <H5
              className={classNames(classes.eyebrow, {
                [classes.leftAlign]: isLeftAligned,
                [classes.rightAlign]: isRightAligned,
                [classes.centerAlign]: isCenterAligned,
                [classes.extraBoldTitle]: extraBoldTitle,
              })}
            >
              {eyebrow}
            </H5>
          )}
          {!!title && (
            <H5
              className={classNames(classes.title, {
                [classes.leftAlign]: isLeftAligned,
                [classes.rightAlign]: isRightAligned,
                [classes.centerAlign]: isCenterAligned,
                [classes.extraBoldTitle]: extraBoldTitle,
              })}
              component={!!eyebrow ? 'div' : 'h5'}
            >
              {title}
            </H5>
          )}
          {!!description && (
            <Description
              className={classNames(classes.description, {
                [classes.noPaddingDescription]: noDescMargin,
                [classes.moreLinkSpace]: moreLinkSpace,
                [classes.leftAlign]: isLeftAligned,
                [classes.rightAlign]: isRightAligned,
                [classes.centerAlign]: isCenterAligned,
                [classes.darkDesc]: variant === 'dark',
              })}
            >
              {description}
            </Description>
          )}
          {!!descriptionInfo && (
            <TextSm
              className={classNames(classes.descriptionInfo, {
                [classes.descriptionInfoDark]: variant === 'dark',
                [classes.leftAlign]: isLeftAligned,
                [classes.rightAlign]: isRightAligned,
                [classes.centerAlign]: isCenterAligned,
              })}
            >
              {<MarkdownHandler>{descriptionInfo}</MarkdownHandler>}
            </TextSm>
          )}
          {!!cta && cta.length > 0 && (
            <TextSm
              component="span"
              className={classNames(classes.link, {
                [classes.leftAlign]: isLeftAligned,
                [classes.rightAlign]: isRightAligned,
                [classes.centerAlign]: isCenterAligned,
              })}
            >
              {renderBloks(cta)}
            </TextSm>
          )}
          {!!bottomLogo && (
            <img className={classes.bottomLogo} src={bottomLogo} alt={alt} />
          )}
        </Box>
      </ImageLinkWrapper>
    </SbEditable>
  )
}

export default ImageTitleDescriptionCard
